import { FC, useMemo } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { useGetDomainsQuery } from "../../../generated/graphql";
import { CreateDomainCard, DomainCard } from "./domain-card";

type IDomainPageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

export const DomainPage: FC<IDomainPageProps> = (props) => {
    const getAllDomainsResponse = useGetDomainsQuery();

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Config.Domain];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Config.CreateDomain);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return (
        <Page routes={routes}>
            <CreateDomainCard isCreating={props.isCreating || props.isEmbedded} refetch={getAllDomainsResponse.refetch} />
            {
                getAllDomainsResponse.data?.Domain.map(domain => (
                    <DomainCard key={domain.Id} domain={domain} refetch={getAllDomainsResponse.refetch} />
                ))
            }
        </Page>
    )
}