import { map } from "lodash"
import { FC, useContext, useEffect } from "react"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { CreateGitWebHookHookStep, GetGitHubIntegrationsDocument, GetGitHubRepositoriesDocument, GetGitHubRepositoryBranchesDocument, GitWebHookHookStep, HookStepType } from "../../../../generated/graphql"
import { CreateIntegrationsCard } from "../../../integrations/integrations-card"
import { HookGraphViewContext } from "../context"
import { HooksStepCard } from "../hooks-step-card"


function getDefaultForm(gitWebHook?: GitWebHookHookStep, disabled?: boolean): IFormVariable[] {
    return [
        {
            name: "githubIntegrationId",
            label: "GitHub integration",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Github integration is required",
            query: GetGitHubIntegrationsDocument,
            transform(data) {
                return map(data?.GitHubIntegration ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                }));
            },
            defaultIcon: Icons.Logos.GitHub,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a GitHub integration" },
            },
            createNode: CreateIntegrationsCard,
            defaultValue: gitWebHook?.GitHubIntegration?.Id,
            disabled,
        },
        {
            name: "url",
            label: "GitHub repository",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Github repository is required",
            query: GetGitHubRepositoriesDocument,
            transform(data) {
                return map(data?.GitHubRepository ?? [], ({ Name, Url }) => ({
                    id: Url,
                    label: Name,
                }));
            },
            onClick(item, setForm) {
                setForm(f => {
                    f.repositoryName = item.label;
                });
            },
            defaultIcon: Icons.Dev.Repository.Default,
            dropdownProps: {
                noItemsLabel: "No repositories found",
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.githubIntegrationId == null || form.githubIntegrationId.length === 0) {
                    return [false, {}];
                }
                return [true, {
                    id: form.githubIntegrationId,
                }]
            },
            defaultValue: gitWebHook?.Url,
            disabled,
        },
        {
            name: "branches",
            label: "Repository branches",
            type: "query",
            fieldType: "text[]",
            validate: (value: string) => value.length > 0,
            errorMessage: "At least one branch is required",
            query: GetGitHubRepositoryBranchesDocument,
            transform(data) {
                return map(data?.GitHubRepositoryBranch ?? [], ({ Name }) => ({
                    id: Name,
                    label: Name,
                }));
            },
            defaultIcon: Icons.Cog,
            dropdownProps: {
                noItemsLabel: "No branches found",
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.githubIntegrationId == null || form.url == null) {
                    return [false, {}];
                }
                if (form.githubIntegrationId.length === 0 || form.url.length === 0) {
                    return [false, {}];
                }
                return [true, {
                    id: form.githubIntegrationId,
                    url: form.url,
                }]
            },
            defaultValue: gitWebHook?.Branches,
            disabled,
        },
        {
            name: "events",
            label: "Events",
            type: "dropdown",
            fieldType: "text[]",
            validate: (events: string) => events.length > 0,
            defaultIcon: Icons.Calendar,
            errorMessage: "Events is required",
            dropdownProps: {
                items: [
                    {
                        id: "push",
                        label: "Push",
                    },
                    {
                        id: "pull_request_merge",
                        label: "Pull Request (Merge)",
                    },
                    {
                        id: "pull_request_open",
                        label: "Pull Request (Open)",
                    },
                    {
                        id: "pull_request_sync",
                        label: "Pull Request (Sync)",
                    },
                ],
            },
            defaultValue: gitWebHook?.Events,
            disabled,
        },
    ]
}


export type ICreateGitWebHookHookStep = {
    Type: HookStepType.GitWebHook;
    GitWebHook: CreateGitWebHookHookStep;
}

export const GitWebHookHookStepCard: FC<IGraphCardProps<GitWebHookHookStep>> = (props) => {
    const { setCacheItem } = useContext(HookGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();
    const disabled = props.data?.Status != null;

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertGitWebHookFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HooksStepCard label="Git web hook setup" {...props} disableActions={disabled}>
            <Form variables={getDefaultForm(props.data, disabled)} defaultExtraValues={{
                repositoryName: props.data?.RepositoryName ?? "",
            }} {...formProps} />
        </HooksStepCard>
    )
}

const convertGitWebHookFormToStep = (form: IForm): ICreateGitWebHookHookStep => {
    return {
        Type: HookStepType.GitWebHook,
        GitWebHook: {
            IntegrationId: form.githubIntegrationId,
            Type: "GitHub",
            RepositoryName: form.repositoryName,
            Url: form.url,
            Branches: form.branches,
            Events: form.events,
        }
    }
}