import { map } from "lodash"
import { FC, useContext, useEffect } from "react"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { CreateFlowOperationHookStep, FlowOperationHookStep, GetFlowsDocument, HookStepType } from "../../../../generated/graphql"
import { CreateFlowPage } from "../../flow/create-flow"
import { HookGraphViewContext } from "../context"
import { HooksStepCard } from "../hooks-step-card"


function getDefaultForm(flowOperation?: FlowOperationHookStep, disabled?: boolean): IFormVariable[] {
    return [
        {
            name: "flowId",
            label: "Flow",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Flow is required",
            query: GetFlowsDocument,
            transform(data) {
                return map(data?.Flow ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                }));
            },
            defaultIcon: Icons.CI_CD.Flow.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a flow" },
            },
            createNode: CreateFlowPage,
            createNodeContainerClassName: "w-2/3",
            defaultValue: flowOperation?.Flow?.Id,
            disabled,
        },
        {
            name: "operation",
            label: "Operation Type",
            type: "dropdown",
            fieldType: "text",
            validate: (events: string) => events.length > 0,
            defaultIcon: Icons.Calendar,
            errorMessage: "Operation type is required",
            dropdownProps: {
                items: [
                    {
                        id: "Run",
                        label: "Run",
                    }
                ],
            },
            defaultValue: flowOperation?.Type,
            disabled,
        },
    ]
}


export type ICreateFlowOperationHookStep = {
    Type: HookStepType.FlowOperation;
    FlowOperation: CreateFlowOperationHookStep;
}

export const FlowOperationHookStepCard: FC<IGraphCardProps<FlowOperationHookStep>> = (props) => {
    const { setCacheItem } = useContext(HookGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();
    const disabled = props.data?.Status != null;

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertFlowOperationFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HooksStepCard label="Flow operations" {...props} disableActions={disabled}>
            <Form variables={getDefaultForm(props.data, disabled)} {...formProps} />
        </HooksStepCard>
    )
}

const convertFlowOperationFormToStep = (form: IForm): ICreateFlowOperationHookStep => {
    return {
        Type: HookStepType.FlowOperation,
        FlowOperation: {
            Type: form.operation,
            FlowId: form.flowId,
        }
    }
}

export const FLOW_OPERATION_HOOK_STEP_EXPOSED_VARIABLES = ["Name", "Status", "BuildLink"];