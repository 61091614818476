import classNames from "classnames";
import { FC, useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IRefetch } from "../../../api/query";
import { CancelButton, DeleteButton, EditButton, SubmitButton, UpdateButton } from "../../../components/button";
import { AdjustableCard, CreateCard, Error, GraphBasedExpandableCard } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Pill } from "../../../components/pill";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { Domain, GetDomainDocument, GetDomainsDocument, useCreateDomainMutation, useDeleteDomainMutation, useUpdateDomainMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";

export const CONFIG_DOMAIN_ICON = {
    component: Icons.Config.Domain.Default,
    bgClassName: "bg-slate-500",
}

function getDefaultForm(domain?: Domain): IFormVariable[] {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: domain?.Name,
        },
        {
            name: "issuerEmail",
            label: "Issuer Email",
            fieldType: "text",
            validate: (email: string) => email.length > 0,
            errorMessage: "Issuer email is required",
            defaultValue: domain?.IssuerEmail,
        },
        {
            name: "domains",
            label: "Domains",
            fieldType: "text[]",
            validate: (domains: string) => domains.length > 0,
            errorMessage: "Domains is required",
            defaultValue: domain?.Domains,
        },
    ]
}

type ICreateDomainCardProps = {
    isEmbedded?: boolean,
    isCreating?: boolean;
    refetch: IRefetch;
}

export const CreateDomainCard: FC<ICreateDomainCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createDomain, { loading }] = useCreateDomainMutation();
    const ref = useRef<Function>();
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createDomain({
            variables: {
                name: form.name,
                issuerEmail: form.issuerEmail,
                domains: form.domains,
            },
            onCompleted: (data) => {
                notify("Domain created successfully", "success");
                ref.current?.();
                props.refetch(data.CreateDomain.Id);
            },
            onError: () => {
                notify("Unable to create domain", "error");
            }
        });
    }, [isFormValid, getForm, createDomain, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating || props.isEmbedded} label="Domain" icon={{
                component: Icons.Add,
                bgClassName: CONFIG_DOMAIN_ICON.bgClassName,
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<Error error={error} />}>
                <div className="flex flex-col gap-1 my-4 grow">
                    <Form variables={getDefaultForm()} {...formProps} />
                </div>
                <div className="flex justify-end items-center gap-2">
                    {!props.isEmbedded && <CancelButton onClick={handleClose} />}
                    <SubmitButton loading={loading} onClick={handleSubmit} />
                </div>
            </CreateCard>
        </>
    )
}

export const DomainIntro: FC<{ domain: Domain }> = (props) => {
    return <div className="flex flex-col grow mt-2">
        <div className={classNames(ClassNames.Text, "text-md")}>
            {props.domain.Name}
        </div>
        <div className="text-xs mt-2 flex flex-wrap gap-1">
            {props.domain.Domains.map(domain => (
                <Pill id={domain} label={domain} ellipses={true} />
            ))}
        </div>
    </div>
}

type IDomainCardProps = {
    domain: Domain;
    refetch: IRefetch;
}

export const DomainCard: FC<IDomainCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteDomain, { loading: deleteLoading }] = useDeleteDomainMutation();
    const [updateDomain, { loading: updateLoading }] = useUpdateDomainMutation();
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        const form = getForm();
        setError("");
        updateDomain({
            variables: {
                id: props.domain.Id,
                name: form.name,
                issuerEmail: form.issuerEmail,
                domains: form.domains,
            },
            onCompleted: (data) => {
                notify("Domain updated successfully", "success");
                props.refetch(data.UpdateDomain.Id);
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update domain", "error");
            }
        });
    }, [isFormValid, getForm, updateDomain, props]);

    const handleDelete = useCallback(() => {
        deleteDomain({
            variables: {
                id: props.domain.Id,
            },
            onCompleted: () => {
                notify("Domain deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete domain", "error");
            },
        });
    }, [props, deleteDomain]);


    return <GraphBasedExpandableCard id={props.domain.Id} type={GraphElements.Domain} icon={CONFIG_DOMAIN_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<Error error={error} />} highlight={searchParams.get("id") === props.domain.Id}>
        <>
            <div className="flex flex-col grow">
                <div className={classNames(ClassNames.Text, "text-md mt-2")}>
                    {props.domain.Name}
                </div>
            </div>
            <div className="flex">
                <EditButton onClick={() => toggleCardExpansion.current?.(true)} />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(props.domain)} {...formProps} />
            </div>
            <div className="flex justify-between items-center">
                <DeleteButton loading={deleteLoading} onClick={handleDelete} />
                <div className="flex items-center gap-2">
                    <CancelButton onClick={handleCancel} />
                    <UpdateButton loading={updateLoading} onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const DomainDashboardCard: FC<{ domain: Domain }> = (props) => {
    return <AdjustableCard showTools={true} icon={CONFIG_DOMAIN_ICON}>
        <DomainIntro domain={props.domain} />
    </AdjustableCard>
}

export const transformDomainData: IDataTransform<Domain>  = (data?: { Domain: Domain[]}) => data?.Domain ?? [];

export const DomainGraphCard: FC<IGraphCardProps<Domain>> = (props) => {
    return <GraphCardLoader loader={GetDomainDocument} transform={data => transformDomainData(data)[0]} {...props}>
        {data => (<DomainDashboardCard domain={data} />)}
    </GraphCardLoader>;
}

export const AddDomainDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Domain"
        query={GetDomainsDocument}
        transform={transformDomainData} type={GraphElements.Domain}
        icon={Icons.Config.Domain.Default}
        link={InternalRoutes.Config.CreateDomain.path} />
    );
}