import { DocumentNode, QueryHookOptions, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useAppSelector } from "../store/hooks";

export const useAppQuery = (query: DocumentNode, hookOptions?: QueryHookOptions) => {
  const [run, response] = useLazyQuery(query, hookOptions);
  const authStatus = useAppSelector(state => state.auth.status);

  useEffect(() => {
    if (authStatus === "success" && !response.called) {
      run();
    }
  }, [authStatus, run, response.called]);

  return response;
}

export type IRefetch<T extends unknown = any> = (() => T) | ((id?: string) => T);