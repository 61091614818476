import { values } from "lodash";
import { ReactNode } from "react";
import { NavigateFunction } from "react-router-dom";
import { AccountPage } from "../page/account/account";
import { UserPage } from "../page/admin/user";
import { ExploreFlowPage } from "../page/ci-cd/flow/explore-flow";
import { FlowPage } from "../page/ci-cd/flow/flow";
import { ExploreHooksPage } from "../page/ci-cd/hooks/explore-hooks";
import { HooksPage } from "../page/ci-cd/hooks/hooks";
import { DomainPage } from "../page/config/domain/domain";
import { EnvironmentVariablePage } from "../page/config/environment-variable/environment-variable";
import { RegistryImagePage } from "../page/container/registry-image/registry-image";
import { RegistryPage } from "../page/container/registry/registry";
import { DashboardPage } from "../page/dashboard/dashboard";
import { ClusterPage } from "../page/deploy/cluster/cluster";
import { ExploreQuickContainerPage } from "../page/deploy/quick-container/explore-quick-container";
import { QuickContainerPage } from "../page/deploy/quick-container/quick-container";
import { IntegrationsPage } from "../page/integrations/integrations";
import { LogoutPage } from "../page/logout";
import { CreateHooksPage } from "../page/ci-cd/hooks/create-hooks";
import { CreateFlowPage } from "../page/ci-cd/flow/create-flow";

export type IInternalRoute = {
    name: string;
    path: string;
    component: ReactNode;
}

export const InternalRoutes = {
    Dashboard: {
        name: "Home",
        path: "/",
        component: <DashboardPage />,
    },
    Admin: {
        User: {
            name: "Users",
            path: "/admin/user",
            component: <UserPage />,
        },
        CreateUser: {
            name: "Create User",
            path: "/admin/user/create",
            component: <UserPage isCreating={true} />,
        },
    },
    Team: {
        Team: {
            name: "Team",
            path: "/team",
            component: <QuickContainerPage />,
        },
        CreateTeam: {
            name: "Team",
            path: "/team/create",
            component: <DashboardPage />,
        },
    },
    Dev: {
        Repository: {
            name: "Repository",
            path: "/repository",
            component: <QuickContainerPage />,
        },
        CreateRepository: {
            name: "Repository",
            path: "/repository/create",
            component: <DashboardPage />,
        },
    },
    Container: {
        Image: {
            name: "Image",
            path: "/container/image",
            component: <RegistryImagePage />,
        },
        CreateImage: {
            name: "Create Registry Image",
            path: "/container/image/create",
            component: <RegistryImagePage isCreating={true} />,
        },
        Registry: {
            name: "Registry",
            path: "/container/registry",
            component: <RegistryPage />,
        },
        CreateRegistry: {
            name: "Create Registry",
            path: "/container/registry/create",
            component: <RegistryPage isCreating={true} />,
        },
    },
    CI_CD: {
        CreateFlow: {
            name: "Flow",
            path: "/cicd/flow/create",
            component: <CreateFlowPage />,
        },
        Flow: {
            name: "Flow",
            path: "/cicd/flow",
            component: <FlowPage />,
        },
        ExploreFlow: {
            name: "Explore",
            path: "/cicd/flow/:id/explore",
            component: <ExploreFlowPage  />,
        },
        CreateHook: {
            name: "Hooks",
            path: "/cicd/hooks/create",
            component: <CreateHooksPage />,
        },
        Hooks: {
            name: "Hooks",
            path: "/cicd/hooks",
            component: <HooksPage />,
        },
        ExploreHooks: {
            name: "Explore",
            path: "/cicd/hooks/:id/explore",
            component: <ExploreHooksPage  />,
        },
    },
    Config: {
        Domain: {
            name: "Domain",
            path: "/config/domain",
            component: <DomainPage />,
        },
        CreateDomain: {
            name: "Create Domain",
            path: "/config/domain/create",
            component: <DomainPage isCreating={true} />,
        },
        EnvironmentVariable: {
            name: "Environment variable",
            path: "/config/environment-variable",
            component: <EnvironmentVariablePage />,
        },
        CreatEnvironmentVariable: {
            name: "Create Environment Variable",
            path: "/config/environment-variable/create",
            component: <EnvironmentVariablePage isCreating={true} />,
        },
    },
    Deployment: {
        QuickContainer: {
            name: "Quick container",
            path: "/deployment/quick-container",
            component: <QuickContainerPage />,
        },
        CreatQuickContainer: {
            name: "Create Quick Container",
            path: "/deployment/quick-container/create",
            component: <QuickContainerPage isCreating={true} />,
        },
        ExploreQuickContainer: {
            name: "Explore",
            path: "/deployment/quick-container/:id/explore",
            component: <ExploreQuickContainerPage  />,
        },
        Cluster: {
            name: "Cluster",
            path: "/deployment/cluster",
            component: <ClusterPage />,
        },
        CreatCluster: {
            name: "Create Cluster",
            path: "/deployment/cluster/create",
            component: <ClusterPage isCreating={true} />,
        },
    },
    Integrations: {
        Integrations: {
            name: "Integrations",
            path: "/integrations",
            component: <IntegrationsPage />,
        },
        CreateIntegrations: {
            name: "Create Integration",
            path: "/integrations/create",
            component: <IntegrationsPage isCreating={true} />,
        }
    },
    Account: {
        name: "Account",
        path: "/account",
        component: <AccountPage />,
    },
    Logout: {
        name: "Logout",
        path: "/logout",
        component: <LogoutPage />,
    },
};

export const getRoutes = (): IInternalRoute[] => {
    const allRoutes: IInternalRoute[] = [];
    const currentRoutes = values(InternalRoutes);
    while (currentRoutes.length > 0) {
        const currentRoute = currentRoutes.shift();
        if (currentRoute == null) {
            continue;
        }
        if ("path" in currentRoute) {
            allRoutes.push(currentRoute);
            continue;
        }
        currentRoutes.push(...values(currentRoute));
    }
    return allRoutes;
}

export function navigateWithPathParameters(navigate: NavigateFunction, path: string, record: Record<string, string>) {
    for (const [key, value] of Object.entries(record)) {
        path = path.replace(`:${key}`, value);
    }
    navigate(path);
}