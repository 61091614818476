export const ClassNames = {
    AppContainer: "app-container",
    Text: "text-neutral-600 dark:text-neutral-300",
    Title: "text-neutral-600 dark:text-neutral-300 font-title",
    DisabledText: "text-neutral-300 dark:text-neutral-500",
    Input: "border text-sm appearance-none rounded-md w-full p-1 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline h-[34px] px-2 dark:text-neutral-300/100 dark:bg-white/10 dark:border-white/5",
    Button: "rounded-lg border dark:border-white/5 flex justify-center items-center text-xs px-2 py-1 cursor-pointer gap-1 bg-white hover:bg-neutral-100 dark:bg-white/10 backdrop-blur-lg",
    OutlinedButton: "text-neutral-600 dark:text-neutral-300 cursor-pointer flex gap-1 hover:gap-2 transition-all items-center border border-neutral-300/50 dark:border-neutral-300/10 px-2 py-1 rounded-xl",
    Ellipses: "max-w-[150px] truncate whitespace-nowrap",
    Icon: "h-[40px] w-[40px] rounded-md flex justify-center items-center shadow border dark:border-white/10 transition-all cursor-pointer hover:shadow-lg group/graphicon relative",
    Card: "bg-white dark:bg-white/5 backdrop-blur-3xl shadow-sm border dark:border-white/5 rounded-3xl p-4 flex flex-col justify-between relative transition-all duration-300",
    Underline: "pb-2 border-b border-b-black/10 dark:border-b-white/10",
}