import { FC, useMemo } from "react";
import { Page } from "../../components/container";
import { InternalRoutes } from "../../config/internal-routes";
import { useGetAllUsersQuery } from "../../generated/graphql";
import { CreateUserCard, UserCard } from "./user-card";

type IUserPageProps = {
  isCreating?: boolean;
}

export const UserPage: FC<IUserPageProps> = (props) => {
  const { data, refetch } = useGetAllUsersQuery();

  const routes = useMemo(() => {
    const newRoutes = [InternalRoutes.Admin.User];
    if (props.isCreating) {
        newRoutes.push(InternalRoutes.Admin.CreateUser);
    }
    return newRoutes;
}, [props.isCreating]);

  return <Page routes={routes}>
    <CreateUserCard refetch={refetch} />
    {
      data?.User.map((user) => (
        <UserCard key={user.Id} user={user} refetch={refetch} />
      ))
    }
  </Page>
}