import { map } from "lodash"
import { FC, useContext, useEffect } from "react"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { CreateGitPullFlowStep, GetGitHubIntegrationsDocument, GetGitHubRepositoriesDocument, GitPullFlowStep } from "../../../../generated/graphql"
import { CreateIntegrationsCard } from "../../../integrations/integrations-card"
import { FlowGraphViewContext } from "../context"
import { FlowElements, FlowStepCard } from "../flow-step-card"


function getDefaultForm(gitPull?: GitPullFlowStep): IFormVariable[] {
    return [
        {
            name: "githubIntegrationId",
            label: "GitHub integration",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Github integration is required",
            query: GetGitHubIntegrationsDocument,
            transform(data) {
                return map(data?.GitHubIntegration ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                }));
            },
            defaultIcon: Icons.Logos.GitHub,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a GitHub integration" },
            },
            createNode: CreateIntegrationsCard,
            defaultValue: gitPull?.GitHubIntegration?.Id,
        },
        {
            name: "url",
            label: "GitHub repository",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Github repository is required",
            query: GetGitHubRepositoriesDocument,
            transform(data) {
                return map(data?.GitHubRepository ?? [], ({ Name, Url }) => ({
                    id: Url,
                    label: Name,
                }));
            },
            onClick(item, setForm) {
                setForm(f => {
                    f.repositoryName = item.label;
                });
            },
            defaultIcon: Icons.Dev.Repository.Default,
            dropdownProps: {
                noItemsLabel: "No repositories found",
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.githubIntegrationId == null || form.githubIntegrationId.length === 0) {
                    return [false, {}];
                }
                return [true, {
                    id: form.githubIntegrationId,
                }]
            },
            defaultValue: gitPull?.Url,
        },
    ]
}


export type ICreateGitPullFlowStep = {
    Type: FlowElements.GitPull;
    GitPull: CreateGitPullFlowStep;
}


export const GitHubPullFlowStepCard: FC<IGraphCardProps<GitPullFlowStep>> = (props) => {
    const { setCacheItem } = useContext(FlowGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertGitPullFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlowStepCard label="Git pull" {...props}>
            <Form variables={getDefaultForm(props.data)} defaultExtraValues={{
                repositoryName: props.data?.RepositoryName ?? "",
            }} {...formProps} />
        </FlowStepCard>
    )
}

const convertGitPullFormToStep = (form: IForm): ICreateGitPullFlowStep => {
    return {
        Type: FlowElements.GitPull,
        GitPull: {
            IntegrationId: form.githubIntegrationId as string,
            Type: "GitHub",
            RepositoryName: form.repositoryName as string,
            Url: form.url as string,
        }
    }
}