import classNames from "classnames";
import { filter, forEach, map, once, toString, values } from "lodash";
import { cloneElement, FC, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { IRefetch } from "../../../api/query";
import { ActionButton, AnimatedButtonDropdown, CancelButton } from "../../../components/button";
import { AdjustableCard, Card, CreateCard, Error, GraphBasedExpandableCard } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { IDropdownItem } from "../../../components/dropdown";
import { Form, IFormGrid, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { PrivateTag } from "../../../components/permissions/private-tag";
import { Pill } from "../../../components/pill";
import { PanelSelector, TabSelector } from "../../../components/selector";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes, navigateWithPathParameters } from "../../../config/internal-routes";
import { GetClustersDocument, GetDockerImageTagsDocument, GetDomainsDocument, GetEnvironmentVariablesDocument, GetQuickContainerDocument, GetQuickContainersDocument, GetRegistryImagesDocument, QuickContainer, useCreateQuickContainerMutation, useGetRegistryImageQuery } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { copyToClipboard, getInstallNamespace, getOptional, getOptionalNumber, isNumeric, toTitleCase } from "../../../utils/functions";
import { CreateDomainCard, transformDomainData } from "../../config/domain/domain-card";
import { CreateEnvironmentVariableCard, EnvironmentVariableCard, transformEnvironmentVariableData } from "../../config/environment-variable/environment-variable-card";
import { CreateRegistryImageCard, transformRegistryImageData } from "../../container/registry-image/registry-image-card";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphBaseCard, GraphCardLoader } from "../../dashboard/graph-card";
import { ClusterType, CreateClusterCard, transformClusterData } from "../cluster/cluster-card";
import { QuickContainerMetricsCard } from "./explore-quick-container";
import { QuickContainerConnections } from "./quick-container-connections";

export const DEPLOY_CLIDEY_QUICK_CONTAINER_ICON = {
    component: Icons.Deploy.QuickContainer.Default,
    className: "h-6 w-auto",
    bgClassName: "bg-sky-500",
};


export function getQuickContainerIcon(quickContainer: QuickContainer) {
    if (quickContainer.Cluster == null) {
        return DEPLOY_CLIDEY_QUICK_CONTAINER_ICON;
    }
    switch (quickContainer.Cluster.Type) {
        case ClusterType.DigitalOcean:
            return {
                ...DEPLOY_CLIDEY_QUICK_CONTAINER_ICON,
                component: Icons.Logos.DigitalOcean,
            };
        case ClusterType.Google:
            return {
                ...DEPLOY_CLIDEY_QUICK_CONTAINER_ICON,
                component: Icons.Logos.Google,
            };
        default:
            return DEPLOY_CLIDEY_QUICK_CONTAINER_ICON;
    }
}

enum Panels {
    Details="Details",
    Data="Data",
    Advanced="Advanced",
    Metrics="Metrics",
}

type ICreateQuickContainerCardProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
    refetch: IRefetch;
}

const portConfigDropdownItems: IDropdownItem[] = map([80, 443, 22, 3000, 5432], (port) => ({ id: toString(port), label: toString(port), icon: Icons.Cog }));
const configs = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000, 4000];
const cpuConfigDropdownItems: IDropdownItem[] = map(configs, (config) => ({ id: toString(config), label: `${config}m`, icon: Icons.Chip }));
const memoryConfigDropdownItems: IDropdownItem[] = map(configs, (config) => ({ id: toString(config), label: `${config}Mi`, icon: Icons.Chip }));
const storageConfigDropdownItems: IDropdownItem[] = map(Array(10), (_, index) => ({ id: toString((index + 1) * 10), label: `${(index + 1) * 10}Gi`, icon: Icons.CircleStacked }));
const instancesConfigDropdownItems: IDropdownItem[] = map(Array(4), (_, index) => ({ id: toString(index + 1), label: toString(index + 1), icon: Icons.SquareStack }));

export function getDetailsDefaultForm(quickContainer?: QuickContainer): IFormGrid {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: quickContainer?.Name,
        },
        {
            name: "imageId",
            label: "Registry Image",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Registry image is required",
            query: GetRegistryImagesDocument,
            transform(data, setForm) {
                const dropdownItems = map(transformRegistryImageData(data), ({ Id, Name, Image, Registry }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Container.Image.Default,
                    extra: {
                        image: Image,
                        registryId: Registry?.Id,
                    },
                }));
                const foundItem = dropdownItems.find(item => item.id === quickContainer?.Containers[0].RegistryImageId);
                if (foundItem != null) {
                    setForm(f => {
                        f.image = foundItem.extra.image;
                        f.registryId = foundItem.extra.registryId;
                    });
                }
                return dropdownItems;
            },
            defaultIcon: Icons.Container.Image.Default,
            onClick(item, setForm) {
                setForm(f => {
                    f.image = item.extra.image;
                    f.registryId = item.extra.registryId;
                });
            },
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a registry image" },
            },
            createNode: CreateRegistryImageCard,
            defaultValue: quickContainer?.Containers[0].RegistryImageId,
        },
        {
            name: "tag",
            label: "Image Tag",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Image tag is required",
            query: GetDockerImageTagsDocument,
            transform(data) {
                return map(data?.DockerRegistryImageTag ?? [], (tag) => ({
                    id: tag,
                    label: tag,
                    icon: Icons.Container.Image.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.registryId == null || form.image == null) {
                    return [false, {}];
                }
                return [true, {
                    id: form.registryId,
                    image: form.image,
                }];
            },
            defaultIcon: Icons.Container.Image.Default,
            defaultValue: quickContainer?.Containers[0].Tag,
            disabled: (form) => form.imageId != null && form.imageId === "",
            allowCustomValues: true,
            dropdownProps: {
                noItemsLabel: "No tags found",
            }
        },
        {
            name: "ports",
            label: "Ports",
            type: "dropdown",
            fieldType: "text[]",
            validate: (ports: string[]) => ports.length > 0 && filter(ports, port => !isNumeric(port)).length === 0,
            defaultIcon: Icons.Cog,
            errorMessage: (ports: string[]) => {
                if (ports.length === 0) {
                    return "At least one port is required";
                }
                return "Valid comma separted ports required";
            },
            dropdownProps: {
                items: portConfigDropdownItems,
            },
            defaultValue: map(quickContainer?.Containers[0].Ports ?? [], toString),
        },
    ]
}

export function getDataDefaultForm(quickContainer?: QuickContainer): IFormGrid {
    return [
        {
            name: "environmentVariableId",
            label: "Environment variable",
            type: "query",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            query: GetEnvironmentVariablesDocument,
            transform(data) {
                return map(transformEnvironmentVariableData(data) ?? [], ({ Id, Name, }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Config.EnvironmentVariable.Default,
                }));
            },
            transformFunction: transformEnvironmentVariableData,
            defaultIcon: Icons.Config.EnvironmentVariable.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create an environment variable" },
            },
            createNode: CreateEnvironmentVariableCard,
            editNode: EnvironmentVariableCard,
            defaultValue: quickContainer?.Containers[0].EnvironmentVariableId,
            clearable: true,
        },
        {
            name: "volumes",
            label: "Persistent paths",
            type: "dropdown",
            fieldType: "text[]",
            validate: () => true,
            defaultIcon: Icons.CircleStacked,
            errorMessage: "",
            dropdownProps: {
                isOpen: false,
            },
            defaultValue: quickContainer?.Containers[0].Volumes ?? [],
        },
        {
            name: "size",
            label: "Storage size",
            type: "dropdown",
            fieldType: "text",
            validate: (size: string) => isNumeric(size),
            defaultIcon: Icons.CircleStacked,
            errorMessage: "Storage size has to be a valid number",
            dropdownProps: {
                items: storageConfigDropdownItems,
            },
            defaultValue: quickContainer?.Size?.toString() ?? "10",
            disabled: (form) => form.volumes == null || form.volumes.length === 0,
        },
    ]
}

export function getAdvancedDefaultForm(quickContainer?: QuickContainer): IFormGrid {
    return [
        [
            {
                name: "instances",
                label: "Instances",
                type: "dropdown",
                fieldType: "text",
                validate: () => true,
                defaultIcon: Icons.SquareStack,
                errorMessage: "",
                dropdownProps: {
                    items: instancesConfigDropdownItems,
                },
                defaultValue: quickContainer?.Instances?.toString() ?? "1",
            },
            {
                name: "cpu",
                label: "CPU",
                type: "dropdown",
                fieldType: "text",
                validate: (cpu: string) => cpu.length > 0 && isNumeric(cpu),
                defaultIcon: Icons.Chip,
                errorMessage: (cpu: string) => {
                    if (cpu.length === 0) {
                        return "CPU is required";
                    }
                    return "CPU has to be a valid number";
                },
                dropdownProps: {
                    items: cpuConfigDropdownItems,
                },
                defaultValue: quickContainer?.Containers[0].ContainerResource?.LimitsCPU?.toString() ?? "100",
            },
            {
                name: "memory",
                label: "Memory",
                type: "dropdown",
                fieldType: "text",
                validate: (memory: string) => memory.length > 0 && isNumeric(memory),
                defaultIcon: Icons.Chip,
                errorMessage: (memory: string) => {
                    if (memory.length === 0) {
                        return "Memory is required";
                    }
                    return "Memory has to be a valid number";
                },
                dropdownProps: {
                    items: memoryConfigDropdownItems,
                },
                defaultValue: quickContainer?.Containers[0].ContainerResource?.LimitsMemory?.toString() ?? "100",
            },
        ],
        {
            name: "domainId",
            label: "Domain",
            type: "query",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            query: GetDomainsDocument,
            transform(data) {
                return map(transformDomainData(data), (domain) => ({
                    id: domain.Id,
                    label: domain.Name,
                    icon: Icons.Config.Domain.Default,
                }));
            },
            transformFunction: transformDomainData,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a domain" },
            },
            createNode: CreateDomainCard,
            defaultIcon: Icons.Config.Domain.Default,
            defaultValue: quickContainer?.Domain?.Id,
            clearable: true,
        },
        {
            name: "clusterId",
            label: "Cluster",
            type: "query",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            query: GetClustersDocument,
            transform(data) {
                return map(transformClusterData(data), (cluster) => ({
                    id: cluster.Id,
                    label: cluster.Name,
                    icon: Icons.Config.Domain.Default,
                }));
            },
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a cluster" },
            },
            createNode: CreateClusterCard,
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: quickContainer?.Cluster?.Id,
            disabled: quickContainer?.Id != null,
            clearable: true,
        },
    ]
}

export const CreateQuickContainerCard: FC<ICreateQuickContainerCardProps> = (props) => {
    const [selectedPanelId, setSelectedPanelId] = useState<string>(Panels.Details);
    const [createQuickContainer,] = useCreateQuickContainerMutation();
    const [detailsFormProps, { isFormValid: isDetailsFormValid, getForm: getDetailsForm }] = useFormHook();
    const [dataFormProps, { isFormValid: isDataFormValid, getForm: getDataForm }] = useFormHook();
    const [advancedFormProps, { isFormValid: isAdvancedFormValid, getForm: getAdvancedForm }] = useFormHook();
    const [error, setError] = useState("");
    const expandCardRef = useRef<Function>();

    const handleClose = useCallback(() => {
        setError("");
        expandCardRef.current?.();
    }, [expandCardRef]);

    const handleSubmit = useCallback((onlyCreate: boolean) => {
        let state = isDetailsFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isDataFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isAdvancedFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        setError("");
        const detailsForm = getDetailsForm();
        const dataForm = getDataForm();
        const advancedForm = getAdvancedForm();
        createQuickContainer({
            variables: {
                name: detailsForm.name,
                containers: [
                    {
                        RegistryImageId: detailsForm.imageId,
                        Tag: detailsForm.tag,
                        Ports: map(detailsForm.ports, port => parseInt(port)),
                        Resource: {
                            LimitsMemory: advancedForm.memory ?? "100",
                            LimitsCPU: advancedForm.cpu ?? "100",
                            RequestsMemory: advancedForm.memory ?? "100",
                            RequestsCPU: advancedForm.cpu ?? "100",
                        },
                        Volumes: dataForm.volumes ?? [],
                        EnvironmentVariableId: getOptional(dataForm.environmentVariableId),
                    },
                ],
                domainId: getOptional(advancedForm.domainId),
                size: getOptionalNumber(dataForm.size),
                clusterId: getOptional(advancedForm.clusterId),
                instances: getOptionalNumber(advancedForm.instances),
                onlyCreate,
            },
            onCompleted: (data) => {
                notify("Quick container created successfully", "success");
                handleClose();
                props.refetch?.(data.CreateQuickContainer.Id);
            },
            onError: () => {
                notify("Unable to create quick container", "error");
            }
        });
    }, [isDetailsFormValid, isDataFormValid, isAdvancedFormValid, getDetailsForm, getDataForm, getAdvancedForm, createQuickContainer, handleClose, props]);

    return (
        <CreateCard isExpanded={props.isCreating || props.isEmbedded} label="Quick container" icon={{
            component: Icons.Add,
            bgClassName: DEPLOY_CLIDEY_QUICK_CONTAINER_ICON.bgClassName,
          }} tag={<Error error={error} />}
          setToggleCallback={(toggle) => expandCardRef.current = toggle}>
            <div className="flex flex-col flex-grow mt-2 gap-2">
                <TabSelector selectedTab={selectedPanelId} tabs={filter(values(Panels), panel => panel !== Panels.Metrics)} onTabSelect={tab => setSelectedPanelId(tab)} />
                <PanelSelector selectedPanelId={selectedPanelId} panels={[
                    {
                        id: Panels.Details,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getDetailsDefaultForm()} defaultExtraValues={getDetailsForm()} {...detailsFormProps} />
                        </div>,
                    },
                    {
                        id: Panels.Data,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getDataDefaultForm()} defaultExtraValues={getDataForm()} {...dataFormProps} />
                        </div>,
                    },
                    {
                        id: Panels.Advanced,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getAdvancedDefaultForm()} defaultExtraValues={getAdvancedForm()} {...advancedFormProps} />
                        </div>,
                    },
                ]} />
                <div className="flex items-center gap-2 justify-end">
                    {!props.isEmbedded && <CancelButton onClick={handleClose} />}
                    <AnimatedButtonDropdown buttons={[
                        {
                            label: "Start",
                            icon: Icons.CheckCircle,
                            labelClassName: "text-green-800 dark:text-green-500",
                            iconClassName: "text-green-600 dark:text-green-500", 
                            onClick: () => handleSubmit(false),
                        },
                        {
                            label: "Just Create",
                            icon: Icons.CheckCircle,
                            labelClassName: "text-green-800 dark:text-green-500",
                            iconClassName: "text-green-600 dark:text-green-500", 
                            onClick: () => handleSubmit(true),
                        },
                    ]} />
                </div>
            </div>
        </CreateCard>
    )
}

export const OpenLinkButton: FC<{ quickContainer: QuickContainer }> = (props) => {
    const handleOpenDefaultLink = useCallback(() => {
        const host = window.location.hostname;
        window.open(`${window.location.protocol}//qc-${props.quickContainer.Id}.${host}`);
    }, [props.quickContainer]);

    const openLinkButtons = useMemo(() => {
        const dropdownItems = [
            {
                label: "Open",
                icon: Icons.RightArrowUp,
                onClick: handleOpenDefaultLink,
                iconClassName: "w-3 h-3",
            }
        ];
        forEach(props.quickContainer.Domain?.Domains ?? [], domain => {
            dropdownItems.push({
                label: `Open ${domain}`,
                icon: Icons.RightArrowUp,
                onClick: () => window.open(`${window.location.protocol}//${domain}`),
                iconClassName: "w-3 h-3",
            });
        });
        return dropdownItems;
    }, [props.quickContainer, handleOpenDefaultLink]);
    
    return <AnimatedButtonDropdown buttons={openLinkButtons} />;
}

export const QuickContainerIntro: FC<{ quickContainer: QuickContainer }> = (props) => {
    const { data } = useGetRegistryImageQuery({
        variables: {
            id: props.quickContainer.Containers[0].RegistryImageId,
        }
    });

    const selectedRegistryImage = transformRegistryImageData(data)?.[0];

    return <div className="flex flex-col grow mt-2 gap-1 min-w-[165px]">
        <div className={twMerge(ClassNames.Text, ClassNames.Ellipses, "text-md max-w-[180px]")}>
            {props.quickContainer.Name}
        </div>
        <div className={classNames(ClassNames.Text, "text-xs flex items-center gap-1")}>
            <strong>Image:</strong> <PrivateTag data={selectedRegistryImage?.Name} size="small" />
        </div>
        <div className={classNames(ClassNames.Text, "text-xs flex items-center gap-1")}>
            <strong>Tag:</strong> <PrivateTag data={selectedRegistryImage?.Name == null ? undefined : props.quickContainer.Containers[0].Tag} size="small" />
        </div>
        <div className={classNames(ClassNames.Text, "text-xs")}>
            <strong>Ports:</strong> {props.quickContainer.Containers[0].Ports.join(",")}
        </div>
    </div>
}

export const QuickContainerInfo: FC<{ quickContainer: QuickContainer }> = (props) => {
    const containerResource = props.quickContainer.Containers[0].ContainerResource;
    return <div className="flex flex-col grow mt-2 gap-1">
        <div className={classNames(ClassNames.Text, "text-xs")}>
            <strong>CPU:</strong> {containerResource?.LimitsCPU}m
        </div>
        <div className={classNames(ClassNames.Text, "text-xs")}>
            <strong>Memory:</strong> {containerResource?.LimitsMemory}Mi
        </div>
        <div className={classNames(ClassNames.Text, "text-xs")}>
            <strong>Volumes:</strong> {props.quickContainer.Containers[0].Volumes?.join(" | ")}
        </div>
        <div className={classNames(ClassNames.Text, "text-xs")}>
            <strong>Instances:</strong> {props.quickContainer.Instances}
        </div>
    </div>
}
type QuickContainerCardProps = {
    quickContainer: QuickContainer;
    refetch: IRefetch;
}

export const QuickContainerCard: FC<QuickContainerCardProps> = (props) => {
    const navigate = useNavigate();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleCardExpansion = useCallback(() => {
        toggleCardExpansion.current?.(true)
    }, [toggleCardExpansion]);

    const exploreButtons = useMemo(() => {
        return [
            {
                label: "Explore",
                icon: Icons.CircleRightArrow,
                onClick: () => navigateWithPathParameters(navigate, InternalRoutes.Deployment.ExploreQuickContainer.path, { id: props.quickContainer.Id }),
                iconClassName: "w-3 h-3",
            },
            {
                label: "Copy internal link",
                icon: Icons.Clipboard,
                onClick: once(() => copyToClipboard(`quick-container-${props.quickContainer.Id}.${getInstallNamespace()}.svc.cluster.local`)),
                iconClassName: "w-3 h-3",
            },
        ]
    }, [navigate, props.quickContainer.Id]);
    
    const quickContainerIcon = useMemo(() => {
        return getQuickContainerIcon(props.quickContainer);
    }, [props.quickContainer]); 

    const status = toTitleCase(props.quickContainer.Status);

    return (
        <GraphBasedExpandableCard className="group/quick-container" id={props.quickContainer.Id} type={GraphElements.QuickContainer} icon={quickContainerIcon} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
            collapsedTag={<Pill id={status} label={status} className="opacity-50 transition-all group-hover/quick-container:opacity-100" />}
            highlight={searchParams.get("id") === props.quickContainer.Id} permissionsEnabled={true}
            bgClassName={DEPLOY_CLIDEY_QUICK_CONTAINER_ICON.bgClassName}
            actionPanels={[
                {
                    id: "connections",
                    component: <QuickContainerConnections quickContainer={props.quickContainer} />,
                    icon: <div className={classNames(ClassNames.Icon, DEPLOY_CLIDEY_QUICK_CONTAINER_ICON.bgClassName)} onClick={handleCardExpansion}>
                        {cloneElement(Icons.LeftRight, {
                            className: classNames("w-8 h-8 stroke-white transition-all hover:scale-110"),
                        })}
                    </div>,
                }
            ]}>
            <>
                <QuickContainerIntro quickContainer={props.quickContainer} />
                <div className="flex gap-1">
                    <AnimatedButtonDropdown buttons={exploreButtons} />
                    <OpenLinkButton quickContainer={props.quickContainer} />
                </div>
            </>
            <div></div>
        </GraphBasedExpandableCard>
    )
}


export const QuickContainerDashboardCard: FC<{ quickContainer: QuickContainer, showInstances?: boolean }> = (props) => {
    const quickContainerIcon = useMemo(() => {
        return getQuickContainerIcon(props.quickContainer);
    }, [props.quickContainer]); 

    const instances = useMemo(() => {
        if (!props.showInstances || props.quickContainer.Instances == null || props.quickContainer.Instances === 1) {
            return <></>;
        }
        const children: ReactNode[] = [];
        for (let i = 1; i <= Math.min(props.quickContainer.Instances-1, 2); i++) {
            const offset = 8*i;
            children.push(<div className="absolute top-0 dark:bg-white/5 backdrop-blur-xl h-full w-full rounded-3xl shadow-md" style={{
                transform: `translate(${offset}px,${offset}px)`,
                zIndex: -i,
            }}></div>);
        }
        return children;
    }, [props.quickContainer.Instances, props.showInstances]);

    return <div className="relative">
        <AdjustableCard showTools={true} icon={quickContainerIcon} tag={<Pill id={props.quickContainer.Status} label={props.quickContainer.Status} />}
            className={classNames({
                "border-red-500/50": props.quickContainer.Status === "Failed",
            })}>
            <QuickContainerIntro quickContainer={props.quickContainer} />
            <QuickContainerInfo quickContainer={props.quickContainer} />
            <div className="flex flex-col gap-1 grow scroll-smooth mt-4 w-full">
                <QuickContainerMetricsCard status={props.quickContainer.Status} quickContainer={props.quickContainer} showLogs={false} />
            </div>
        </AdjustableCard>
        {instances}
    </div>
}

export const transformQuickContainersData: IDataTransform<QuickContainer> = (data: { QuickContainer: QuickContainer[] }) => data.QuickContainer;

export const QuickContainerGraphCard: FC<IGraphCardProps<QuickContainer>> = (props) => {
    return <GraphCardLoader loader={GetQuickContainerDocument} transform={(data) => transformQuickContainersData(data)[0]} {...props}>
        {(data: QuickContainer) => (<QuickContainerDashboardCard quickContainer={data} showInstances={true} />)}
    </GraphCardLoader>;
}

const zoomOutResolution = 366/1280;

export const QuickContainerPreviewGraphCard: FC<IGraphCardProps<QuickContainer>> = (props) => {
    const webPage = useMemo(() => {
        const link = props.id.split(":")[1];
        if (link == null) {
            return;
        }
        return `${window.location.protocol}//${link}`;
    }, [props.id]);

    const handleOpenLink = useCallback(() => {
        window.open(webPage, "_blank");
    }, [webPage]);

    return <GraphBaseCard {...props}>
        <Card className="w-[400px] h-[400px] group/preview overflow-hidden">
            {
                webPage != null &&
                <div className="w-full h-full" style={{
                    zoom: zoomOutResolution,
                }}>
                    <iframe src={webPage} className="w-[1280px] h-full pointer-events-none" title="Preview" />
                </div>
            }
            <div className="absolute inset-0  group-hover/preview:bg-black/20" />
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 scale-125 group-hover/preview:opacity-100">
                <ActionButton icon={Icons.RightArrowUp} onClick={handleOpenLink} />
            </div>
        </Card>
    </GraphBaseCard>;
}

export const AddQuickContainerDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Quick Container"
        query={GetQuickContainersDocument}
        transform={transformQuickContainersData} type={GraphElements.QuickContainer}
        icon={Icons.Deploy.QuickContainer.Default}
        link={InternalRoutes.Deployment.CreatQuickContainer.path} />
    );
}