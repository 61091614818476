import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateCard } from "../../../components/card";
import { Page } from "../../../components/container";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { useGetFlowsQuery } from "../../../generated/graphql";
import { CI_CD_FLOW_ICON, FlowCard } from "./flow-card";

export const FlowPage: FC = () => {
    const navigate = useNavigate();
    const state = useLocation().state;
    const getFlowsResponse = useGetFlowsQuery({
        fetchPolicy: state?.refetch ? "network-only" : "cache-first",
    });
    return (
        <Page routes={[InternalRoutes.CI_CD.Flow]}>
            <CreateCard label="Flow" icon={{
                component: Icons.Add,
                bgClassName: CI_CD_FLOW_ICON.bgClassName,
            }} onClick={() => navigate(InternalRoutes.CI_CD.CreateFlow.path)} />
            {
                getFlowsResponse.data?.Flow?.map(flow => (
                    <FlowCard key={flow.Id} flow={flow} refetch={getFlowsResponse.refetch} />
                ))
            }
        </Page>
    )
}