import { map } from "lodash";
import { Route, Routes } from "react-router-dom";
import { Container } from "./components/container";
import { Notifications } from "./components/notifications";
import { GlobalSearchBar } from "./components/searchbar/searchbar";
import { Sidebar } from "./components/sidebar/sidebar";
import { getRoutes } from "./config/internal-routes";
import classNames from "classnames";
import { ClassNames } from "./components/classes";

function App() {
  return (
    <div className={classNames("flex", ClassNames.AppContainer)}>
      <GlobalSearchBar />
      <Notifications />
      <Sidebar />
      <Container>
        <Routes>
          {map(getRoutes(), route => (
            <Route path={route.path} element={route.component} />
          ))}
        </Routes>
      </Container>
    </div>
  );
}

export default App;
