import { FC, useMemo } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { EnvironmentVariable, useGetEnvironmentVariablesQuery } from "../../../generated/graphql";
import { CreateEnvironmentVariableCard, EnvironmentVariableCard, transformEnvironmentVariableData } from "./environment-variable-card";

type IEnvironmentVariablePageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

export const EnvironmentVariablePage: FC<IEnvironmentVariablePageProps> = (props) => {
    const getAllEnvironmentVariablesResponse = useGetEnvironmentVariablesQuery();

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Config.EnvironmentVariable];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Config.CreatEnvironmentVariable);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return (
        <Page routes={routes}>
            <CreateEnvironmentVariableCard isCreating={props.isCreating || props.isEmbedded} refetch={getAllEnvironmentVariablesResponse.refetch} />
            {
                transformEnvironmentVariableData(getAllEnvironmentVariablesResponse.data).map((environmentVariable: EnvironmentVariable) => (
                    <EnvironmentVariableCard key={environmentVariable.Id} data={environmentVariable} refetch={getAllEnvironmentVariablesResponse.refetch} />
                ))
            }
        </Page>
    )
}