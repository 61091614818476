import { FC, useMemo } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { Registry, useGetRegistriesQuery } from "../../../generated/graphql";
import { CreateRegistryCard, RegistryCard } from "./registry-card";

type IRegistryPageProps = {
  isEmbedded?: boolean;
  isCreating?: boolean;
}

export const RegistryPage: FC<IRegistryPageProps> = (props) => {
  const getAllRegistriesQueryResponse = useGetRegistriesQuery();

  const routes = useMemo(() => {
    const newRoutes = [InternalRoutes.Container.Registry];
    if (props.isCreating || props.isEmbedded) {
      newRoutes.push(InternalRoutes.Container.CreateRegistry);
    }
    return newRoutes;
  }, [props.isCreating, props.isEmbedded]);

  return <Page routes={routes}>
    <CreateRegistryCard isCreating={props.isCreating || props.isEmbedded} refetch={getAllRegistriesQueryResponse.refetch} />
    {
      getAllRegistriesQueryResponse.data?.Registry.map(registry => (
        <RegistryCard key={registry.Id} registry={registry as Registry} refetch={getAllRegistriesQueryResponse.refetch} />
      ))
    }
  </Page>
}