import { find } from "lodash";
import { FC, useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { EditButton } from "../../../components/button";
import { AdjustableCard, GraphBasedExpandableCard } from "../../../components/card";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Pill } from "../../../components/pill";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { GetGoogleIntegrationDocument, GetGoogleIntegrationsDocument, useDeleteGoogleIntegrationMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { OAuthElements, OAuthLoginCard } from "../common/oauth-login-card";
import { IRefetch } from "../../../api/query";

export const GOOGLE_INTEGRATION_ICON = {
    component: Icons.Logos.Google,
    bgClassName: "bg-white",
};

export type IGoogleIntegration = {
    Id: string;
    Name: string;
    Expiry: string;
    Scopes: string[];
}

type IGoogleOAuthIntegrationsLoginCardProps = {
    name?: string;
    hideTitle?: boolean;
    googleIntegration?: IGoogleIntegration;
    onCancel: () => void,
    onDelete?: () => void;
    refetch?: IRefetch;
}

const CLOUD_PLATFORM_SCOPE = "https://www.googleapis.com/auth/cloud-platform";

export const GoogleOAuthIntegrationsLoginCard: FC<IGoogleOAuthIntegrationsLoginCardProps> = ({ name, hideTitle, googleIntegration, onCancel, onDelete, refetch }) => {
    const scopes = useMemo(() => googleIntegration?.Scopes ?? [], [googleIntegration]);
    
    return (<OAuthLoginCard element={OAuthElements.GOOGLE} icon={Icons.Logos.Google} name={name}
            label={"Google OAuth"} onCancel={onCancel} scopes={[
                {
                    scope: CLOUD_PLATFORM_SCOPE,
                    description: "Required for getting kubernetes clusters",
                    checked: find(scopes, scope => scope === CLOUD_PLATFORM_SCOPE) != null,
                },
            ]} hideTitle={hideTitle} onDelete={onDelete} id={googleIntegration?.Id} refetch={refetch} />)
}


type IGoogleIntegrationsCardProps = {
    refetch: IRefetch;
    googleIntegration: IGoogleIntegration;
}

const GoogleIntegrationIntro: FC<{ googleIntegration: IGoogleIntegration }> = (props) => {
    return <div className="flex flex-col gap-2 grow">
        <div className="text-md mt-2">
            {props.googleIntegration.Name}
        </div>
        <div className="flex gap-1">
            {
                props.googleIntegration.Scopes.length > 0 &&
                <>
                    <Pill id={props.googleIntegration.Scopes[0]} label={props.googleIntegration.Scopes[0]} ellipses={true} />
                    {
                        props.googleIntegration.Scopes.length > 1 &&
                        <Pill id={`${props.googleIntegration.Scopes.length - 1}+`} label={`${props.googleIntegration.Scopes.length - 1}+`} />
                    }
                </>
            }
        </div>
    </div>
}

export const GoogleIntegrationsCard: FC<IGoogleIntegrationsCardProps> = (props) => {
    const [deleteGoogleIntegration] = useDeleteGoogleIntegrationMutation();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleOpen = useCallback(() => {
        toggleCardExpansion.current?.(true);
    }, [toggleCardExpansion]);

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.(false);
    }, [toggleCardExpansion]);

    const handleDelete = useCallback(() => {
        deleteGoogleIntegration({
            variables: {
                id: props.googleIntegration.Id,
            },
            onCompleted: () => {
                notify("Google integration deleted successfully!", "success");
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to delete Google integration", "error");
            },
        });
    }, [props, deleteGoogleIntegration]);


    return (
        <GraphBasedExpandableCard id={props.googleIntegration.Id} type={GraphElements.GoogleIntegration} icon={GOOGLE_INTEGRATION_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle} highlight={searchParams.get("id") === props.googleIntegration.Id}>
            <>
                <GoogleIntegrationIntro googleIntegration={props.googleIntegration} />
                <div className="flex">
                    <EditButton onClick={handleOpen} />
                </div>
            </>
            <div className="flex flex-col grow">
                <div className="text-lg mt-4">
                    Update Google Integration
                </div>
                <GoogleOAuthIntegrationsLoginCard name={props.googleIntegration.Name} onCancel={handleCancel} hideTitle={true} googleIntegration={props.googleIntegration} onDelete={handleDelete} />
            </div>
        </GraphBasedExpandableCard>
    )
}

export const GoogleIntegrationDashboardCard: FC<{ google: IGoogleIntegration }> = (props) => {
    return <AdjustableCard showTools={true} icon={GOOGLE_INTEGRATION_ICON}>
        <GoogleIntegrationIntro googleIntegration={props.google} />
    </AdjustableCard>
}

export const transformGoogleIntegrationData: IDataTransform<IGoogleIntegration>  = (data?: { GoogleIntegration: IGoogleIntegration[]}) => data?.GoogleIntegration ?? [];

export const GoogleIntegrationGraphCard: FC<IGraphCardProps<IGoogleIntegration>> = (props) => {
    return <GraphCardLoader loader={GetGoogleIntegrationDocument} transform={data => transformGoogleIntegrationData(data)[0]} {...props}>
        {(data: IGoogleIntegration) => (<GoogleIntegrationDashboardCard google={data} />)}
    </GraphCardLoader>;
}

export const AddGoogleIntegrationDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Google Integration"
        query={GetGoogleIntegrationsDocument}
        transform={transformGoogleIntegrationData} type={GraphElements.GoogleIntegration}
        icon={Icons.Logos.Google}
        link={InternalRoutes.Integrations.CreateIntegrations.path} />
    );
}