import { FC, useMemo } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { QuickContainer, useGetQuickContainersQuery, useGetQuickContainerStatusSubscription } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { CreateQuickContainerCard, QuickContainerCard } from "./quick-container-card";
import { useLocation } from "react-router-dom";

type IQuickContainerPageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

const statusToListen = new Set(["Deleted", "Stopped", "Started","Failed"]);

export const QuickContainerPage: FC<IQuickContainerPageProps> = (props) => {
    const state = useLocation().state;
    const getAllQuickContainersResponse = useGetQuickContainersQuery({
        fetchPolicy: state?.refetch ? "network-only" : "cache-first",
    });
   
    useGetQuickContainerStatusSubscription({
        variables: {
            ids: getAllQuickContainersResponse.data?.QuickContainer?.map(quickContainer => quickContainer.Id) ?? [], 
        },
        onData({ data: { data } }) {
            const status = data?.ListenQuickContainerStatus;
            if (status == null) {
                return;
            }
            if (!statusToListen.has(status.Status)) {
                return;
            }
            const quickContainer = getAllQuickContainersResponse.data?.QuickContainer?.find(qc => qc.Id === status.Id);
            if (quickContainer == null) {
                return;
            }
            notify(`${quickContainer.Name} has ${status.Status.toLowerCase()}.`, status.Status === "Failed" ? "error" : "success");
            getAllQuickContainersResponse.refetch();
        },
        onError() {},
    });

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Deployment.QuickContainer];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Deployment.CreatQuickContainer);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return (
        <Page routes={routes}>
            <CreateQuickContainerCard isCreating={props.isCreating || props.isEmbedded} refetch={getAllQuickContainersResponse.refetch} />
            {
                getAllQuickContainersResponse.data?.QuickContainer.map(quickContainer => (
                    <QuickContainerCard key={quickContainer.Id} quickContainer={quickContainer as QuickContainer} refetch={getAllQuickContainersResponse.refetch} />
                ))
            }
        </Page>
    )
}