import { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Page } from "../../components/container";
import { InternalRoutes } from "../../config/internal-routes";
import { DigitalOceanIntegration, DockerIoRegistryIntegration, GitHubIntegration, GoogleIntegration, MailIntegration, useGetIntegrationsQuery } from "../../generated/graphql";
import { notify } from "../../store/function";
import { DigitalOceanIntegrationsCard } from "./digital-ocean-integrations/digital-ocean-integrations-card";
import { DockerIOIntegrationsCard } from "./docker-io-integrations/docker-io-integrations-card";
import { GitHubIntegrationsCard } from "./github-integrations/github-integrations-card";
import { GoogleIntegrationsCard } from "./google/google-integrations-card";
import { CreateIntegrationsCard } from "./integrations-card";
import { MailIntegrationsCard } from "./mail/mail-integrations-card";
import { getOAuthUrlCallbackLocationStorageCacheId, OAuthElements } from "./common/oauth-login-card";

type IIntegrationsPageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

export const IntegrationsPage: FC<IIntegrationsPageProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, refetch } = useGetIntegrationsQuery();

    useEffect(() => {
        if (searchParams.has("success")) {
            const successMessage = searchParams.get("success")!;
            if (searchParams.has("id") && searchParams.has("type")) {
                localStorage.setItem(getOAuthUrlCallbackLocationStorageCacheId(searchParams.get("type") as OAuthElements), JSON.stringify({
                    id: searchParams.get("id"),
                    type: "success",
                    message: successMessage,
                }));
            } else {
                searchParams.delete("success");
                searchParams.delete("id");
                setSearchParams(searchParams);
                notify(successMessage, "success");
            }
        }
        if (searchParams.has("error")) {
            const errorMessage = searchParams.get("error")!;
            notify(errorMessage, "error");
            localStorage.setItem(getOAuthUrlCallbackLocationStorageCacheId(searchParams.get("type") as OAuthElements), JSON.stringify({
                id: searchParams.get("id"),
                type: "error",
                message: errorMessage,
            }));
            searchParams.delete("error");
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Integrations.Integrations];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Integrations.CreateIntegrations);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return (
        <Page routes={routes}>
            <CreateIntegrationsCard isCreating={props.isCreating || props.isEmbedded} refetch={refetch} />
            {data?.Integration.DockerIORegistryIntegration.map((dockerIORegistry: DockerIoRegistryIntegration) => (
                <DockerIOIntegrationsCard key={dockerIORegistry.Id} dockerIORegistry={dockerIORegistry} refetch={refetch} />
            ))}
            {data?.Integration.GitHubIntegration.map((githubIntegration: GitHubIntegration) => (
                <GitHubIntegrationsCard key={githubIntegration.Id} githubIntegration={githubIntegration} refetch={refetch} />
            ))}
            {data?.Integration.DigitalOceanIntegration.map((digitalOceanIntegration: DigitalOceanIntegration) => (
                <DigitalOceanIntegrationsCard key={digitalOceanIntegration.Id} digitalOceanIntegration={digitalOceanIntegration} refetch={refetch} />
            ))}
            {data?.Integration.GoogleIntegration.map((googleIntegration: GoogleIntegration) => (
                <GoogleIntegrationsCard key={googleIntegration.Id} googleIntegration={googleIntegration} refetch={refetch} />
            ))}
            {data?.Integration.MailIntegration.map((mailIntegration: MailIntegration) => (
                <MailIntegrationsCard key={mailIntegration.Id} mailIntegration={mailIntegration} refetch={refetch} />
            ))}
        </Page>
    )
}