import { Omit } from "@reduxjs/toolkit/dist/tsHelpers";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isArray } from "lodash";
import {
  FC,
  ReactElement,
  ReactNode,
  cloneElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { GraphElements } from "../config/constants";
import { Icons } from "../config/icons";
import { InternalRoutes } from "../config/internal-routes";
import { toTitleCase } from "../utils/functions";
import { ActionButton, AnimatedButton } from "./button";
import { ClassNames } from "./classes";
import { AnimatedDropdown } from "./dropdown";
import { createRedirectState } from "./graph/graph";
import { Loading } from "./loading";
import { PermissionCard } from "./permissions/permissions";
import { IPanel, PanelSelector } from "./selector";

type ICardIcon = {
  component: ReactElement;
  bgClassName?: string;
  className?: string;
};

type ICardProps = {
  className?: string;
  icon?: ICardIcon | ReactElement;
  tag?: ReactElement;
  children: ReactElement[] | ReactElement | ReactNode;
  loading?: boolean;
  highlight?: boolean;
  loadingText?: string;
};

export const Icon: FC<ICardIcon> = memo((propsIcon) => (<div
  className={twMerge(classNames(
    ClassNames.Icon,
    propsIcon.bgClassName
  ))}
>
  {cloneElement(propsIcon.component, {
    className: twMerge(classNames("w-8 h-8 stroke-white", propsIcon.className)),
  })}
</div>));

export const Error: FC<{ error: ReactNode }> = ({ error }) => <div className="flex flex-col gap-1 items-end">
  <div className="text-red-800 dark:text-red-500 text-xs">{error}</div>
</div>;

export const Card: FC<ICardProps> = ({
  children,
  className,
  icon: propsIcon,
  tag,
  highlight,
  loading,
  loadingText,
}) => {
  const [highlightStatus, setHighlightStatus] = useState(highlight);

  useEffect(() => {
    if (highlight) {
      setTimeout(() => {
        setHighlightStatus(false);
      }, 3000);
    }
  }, [highlight]);

  const icon = useMemo(() => {
      if (propsIcon == null) {
        return null;
      }
      if ("component" in propsIcon) {
        return <Icon {...propsIcon} />
      }
      return propsIcon;
  }, [propsIcon]);

  return (
    <motion.div
      className={twMerge(
        classNames(
          ClassNames.Card,
          "h-[200px] w-[200px]",
          {
            "shadow-2xl z-10 dark:shadow-black": highlightStatus,
          },
          className
        )
      )}
    >
      {loading ? (
        <Loading loadingText={loadingText} />
      ) : (
        <>
          <div className="flex justify-between items-start">
            {icon}
            {tag}
          </div>
          {children}
        </>
      )}
    </motion.div>
  );
};

type IExpandableCardProps = {
  isExpanded?: boolean;
  children: [ReactElement, ReactElement];
  borderless?: boolean;
  setToggleCallback?: (callback: (status: boolean) => void) => void;
  collapsedTag?: ReactElement;
} & ICardProps;

export const ExpandableCard: FC<IExpandableCardProps> = (props) => {
  const [expand, setExpand] = useState(props.isExpanded);

  useEffect(() => {
    props.setToggleCallback?.(setExpand);
  }, [props]);

  useEffect(() => {
    setExpand(props.isExpanded);
  }, [props.isExpanded]);

  if (props.borderless) {
    return <AnimatePresence mode="sync">
      <motion.div
        key={props.loading ? "loading" : expand ? "expand" : "collapse"}
        className="flex flex-col grow"
        initial={{ opacity: 0 }}
        animate={{ opacity: 100, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.02 } }}
      >
        {props.loading ? (
          <Loading />
        ) : expand ? (
          props.children[1]
        ) : (
          props.children[0]
        )}
      </motion.div>
    </AnimatePresence>;
  }

  return (
    <AnimatePresence mode="sync">
      <Card
        {...props}
        className={classNames(props.className, {
          "w-[400px] h-[400px]": expand,
        })}
        tag={expand ? props.tag : props.collapsedTag}
      >
        <AnimatePresence mode="sync">
          <motion.div
            key={props.loading ? "loading" : expand ? "expand" : "collapse"}
            className="flex flex-col grow"
            initial={{ opacity: 0 }}
            animate={{ opacity: 100, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.02 } }}
          >
            {props.loading ? (
              <Loading />
            ) : expand ? (
              props.children[1]
            ) : (
              props.children[0]
            )}
          </motion.div>
        </AnimatePresence>
      </Card>
    </AnimatePresence>
  );
};

const DEFAULT_ADD_CARD_ICON = {
  component: Icons.Add,
  bgClassName: "bg-teal-500",
};

type ICreateCardProps = {
  label: string;
  children?: ReactElement | ReactElement[];
  actionLabel?: string;
  icon?: ICardIcon;
  onClick?: () => void;
  loadingText?: string;
} & Omit<IExpandableCardProps, "children" | "icon">;

export const CreateCard: FC<ICreateCardProps> = (props) => {
  const toggleExpandRef = useRef<(status: boolean) => void>();

  const handleExpand = useCallback(() => {
    toggleExpandRef.current?.(true);
  }, [toggleExpandRef]);

  useEffect(() => {
    if (toggleExpandRef.current != null) {
      props.setToggleCallback?.(toggleExpandRef.current);
    }
  }, [toggleExpandRef, props]);

  const lowerCaseLabel = useMemo(() => {
    return toTitleCase(props.label);
  }, [props.label]);

  return (
    <ExpandableCard
      {...props}
      loadingText={props.loadingText ?? `Creating ${lowerCaseLabel}`}
      icon={props.icon ?? DEFAULT_ADD_CARD_ICON}
      setToggleCallback={(ref) => (toggleExpandRef.current = ref)}
    >
      <>
        <div className={classNames(ClassNames.Text, "grow text-lg mt-2")}>{`Create ${lowerCaseLabel}`}</div>
        <div className="flex items-center justify-end">
          <AnimatedButton
            loading={props.loading}
            label={props.actionLabel ?? "Create"}
            icon={props.icon?.component ?? Icons.Add}
            onClick={props.onClick ?? handleExpand}
          />
        </div>
      </>
      <>{props.children}</>
    </ExpandableCard>
  );
};

type IAdjustableCardProps = {
  icon: {
    component: ReactElement;
    bgClassName?: string;
    className?: string;
  };
  tag?: ReactElement;
  children:
    | ReactElement
    | [ReactElement, ReactElement]
    | [ReactElement, ReactElement, ReactElement];
  actions?: ReactElement | ReactElement[];
  showTools?: boolean;
  isExpanded?: boolean;
  className?: string;
};

export const AdjustableCard: FC<IAdjustableCardProps> = (props) => {
  const [expandWidth, setExpandedWidth] = useState(props.isExpanded ?? false);
  const [expandHeight, setExpandedHeight] = useState(props.isExpanded ?? false);

  const handleToggleWidth = useCallback(
    () => setExpandedWidth((status) => !status),
    []
  );
  const handleToggleHeight = useCallback(
    () => setExpandedHeight((status) => !status),
    []
  );

  const children = isArray(props.children) ? props.children : [props.children];

  return (
    <div
      className={twMerge(
        classNames(
          "group/card h-[200px] w-[200px] rounded-3xl p-4 flex flex-col justify-between relative transition-all duration-300 bg-white dark:bg-white/5 backdrop-blur-3xl shadow-md border dark:border-white/5",
          {
            "w-[400px]": expandWidth,
            "h-[400px]": expandHeight,
          },
          props.className,
        )
      )}
    >
      <div className="flex flex-col gap-2 flex-grow">
        <div className="flex justify-between items-start">
          <Icon {...props.icon} />
          {props.tag}
        </div>
        <div
          className={classNames("flex", {
            "flex-col gap-10": expandHeight && expandWidth,
            "flex-row": !(expandHeight && expandWidth),
          })}
        >
          <div
            className={classNames("flex flex-wrap h-full", {
              "flex-col gap-20": expandHeight && !expandWidth,
              "flex-row": !expandHeight,
            })}
          >
            <div className="animate-fade flex-1 transition-all">
              {children[0]}
            </div>
            <div
              className={classNames(
                "pointer-events-none flex-1 transition-all delay-500",
                {
                  "animate-fade pointer-events-auto":
                    expandWidth || expandHeight,
                  hidden: !(expandWidth || expandHeight),
                }
              )}
            >
              {children[1]}
            </div>
            <div
              className={classNames(
                "pointer-events-none w-full transition-all delay-500",
                {
                  "animate-fade pointer-events-auto":
                    expandWidth && expandHeight,
                  hidden: !(expandWidth && expandHeight),
                }
              )}
            >
              {children[2]}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">{props.actions}</div>
      {props.showTools && children.length > 1 && (
        <div className="opacity-0 group-hover/card:opacity-100 transition-all">
          {!(expandHeight && children.length === 2) && (
            <div
              className="z-[2] absolute -right-3 bottom-4 w-6 h-6 bg-white dark:bg-white/5 backdrop-blur-3xl border dark:border-none rounded-full flex justify-center items-center transition-all cursor-pointer hover:scale-110"
              onClick={handleToggleWidth}
            >
              {cloneElement(Icons.DoubleRightArrow, {
                className: classNames("w-4 h-4 duration-300 stroke-neutral-500 dark:stroke-neutral-300", {
                  stroke: "rgba(0,0,0,0.1)",
                  "-rotate-180": expandWidth,
                }),
              })}
            </div>
          )}
          {!(expandWidth && children.length === 2) && (
            <div
              className="z-[2] absolute right-4 -bottom-3 w-6 h-6 bg-white dark:bg-white/5 backdrop-blur-3xl border dark:border-none rounded-full flex justify-center items-center transition-all cursor-pointer rotate-90 hover:scale-110"
              onClick={handleToggleHeight}
            >
              {cloneElement(Icons.DoubleRightArrow, {
                className: classNames("w-4 h-4 duration-300 stroke-neutral-500 dark:stroke-neutral-300", {
                  "-rotate-180": expandHeight,
                }),
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type IGraphBasedExpandableCardProps = IExpandableCardProps & {
  id: string;
  type: GraphElements;
  permissionsEnabled?: boolean;
  actionPanels?: (IPanel & { icon: ReactElement })[];
  bgClassName?: string;
};

type QuickContainerIconProps = {
  icon: ICardIcon;
  bgClassName?: string;
  onClick: () => void;
}

const GraphCardIcon: FC<QuickContainerIconProps & { onActionClick: () => void }> = (props) => (
  <div className={twMerge(classNames(ClassNames.Icon, props.icon.bgClassName))} onClick={props.onClick}>
    {cloneElement(props.icon.component, {
      className: classNames("w-8 h-8 stroke-white", props.icon.className),
    })}
    <div className="absolute -top-2 -right-2 opacity-0 transition-all group-hover/graphicon:opacity-100">
        <ActionButton containerClassName="h-6 w-6" className="h-4 w-4" icon={Icons.RightArrowUp} onClick={props.onActionClick} />
    </div>
  </div>
);

const GraphPermissionIcon: FC<QuickContainerIconProps> = (props) => (
  <div className={classNames(ClassNames.Icon, props.bgClassName)} onClick={props.onClick}>
    {cloneElement(Icons.Lock, {
      className: classNames("w-8 h-8 stroke-white transition-all hover:scale-110", props.icon.className),
    })}
  </div>
)

export const GraphBasedExpandableCard: FC<IGraphBasedExpandableCardProps> = (props) => {
  const toggleCardRef = useRef<Function>();
  const [selectedPanelId, setSelectedPanelId] = useState<string>("children");
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    setSelectedPanelId("children");
    toggleCardRef.current?.(false);
  }, []);

  const handleGoTo = useCallback(() => {
    navigate(InternalRoutes.Dashboard.path, {
      state: createRedirectState([
        {
          id: props.id,
          type: props.type
        }
      ]),
    });
  }, [navigate, props.id, props.type]);

  const handleOpen = useCallback((panelId: string) => {
    toggleCardRef.current?.(true);
    setSelectedPanelId(panelId);
  }, []);
  
  const icon = useMemo(() => {
    if (props.icon == null) {
      return <></>;
    }
    if ("component" in props.icon) {
      let items = [
        <GraphCardIcon icon={props.icon} onClick={selectedPanelId === "children" ? handleGoTo : handleCancel} onActionClick={handleGoTo} />,
      ];
      if (props.permissionsEnabled) {
        items.push(<GraphPermissionIcon bgClassName={props.bgClassName} icon={props.icon} onClick={() => handleOpen("permissions")} />);
      }
      if (props.actionPanels != null) {
        items.push(...props.actionPanels.map(actionPanel => <span onClick={() => handleOpen(actionPanel.id)}>{actionPanel.icon}</span> ));
      }
      if (selectedPanelId === "children") {
        return <AnimatedDropdown items={items} />;
      }
      let index = 0;
      if (selectedPanelId === "permissions") {
        index = 1;
      }
      if (selectedPanelId !== "children" && props.actionPanels != null) {
        index = props.actionPanels.findIndex(panel => panel.id === selectedPanelId) + 2;
      }
      const element = items.splice(index, 1);
      items = [...element, ...items];
      return <AnimatedDropdown items={items} />
    }
    return props.icon;
  }, [props.icon, props.permissionsEnabled, props.actionPanels, props.bgClassName, selectedPanelId, handleGoTo, handleCancel, handleOpen]);

  return <ExpandableCard {...props} icon={icon} children={[
     props.children[0],
     <PanelSelector selectedPanelId={selectedPanelId} panels={[
      {
        id: "permissions",
        component: <PermissionCard id={props.id} type={props.type} onCancel={handleCancel} /> ,
      },
      {
        id: "children",
        component: props.children[1],
      },
      ...(props.actionPanels?.map(actionPanel => ({
          ...actionPanel,
          component: cloneElement(actionPanel.component, {
            onCancel: handleCancel,
          }),
        })) ?? []),
     ]} />
  ]} setToggleCallback={ref => {
    toggleCardRef.current = ref;
    props.setToggleCallback?.(ref);
  }} />
}